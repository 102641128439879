import React,{useEffect,useContext,useState} from 'react';
import axios from "axios";
import {Link} from 'react-router-dom';
import {InfoContext} from '../store/InfoContext';
import SingleQuestion from './SingleQuestion';
import { useHistory } from 'react-router';

const Questions=()=> {

const value=useContext(InfoContext);
const {list,setList}=useContext(InfoContext);
const [list1,setList1]=useState([]);
const {questionToAnswer,setquestionToAnswer}=useContext(InfoContext);
const history=useHistory();
const {questionNo,setquestionNo}=useContext(InfoContext);
const {answerFeedback,setanswerFeedback}=useContext(InfoContext);
const [respondentId,setrespondentId]=useState('');

  useEffect(async () => {
    const totalQuestions = await axios(
      value.mainURL+'getQuestionsTotal/'+value.clientId+'/'+value.userType.toLowerCase()+'Experience',
    );

    value.setQuestionNumber(totalQuestions.data);
    const questions = await axios(
      value.mainURL+'getQuestions/'+value.clientId+'/'+value.userType.toLowerCase()+'Experience',
    );




    const id = await axios(
      value.mainURL+'getIdofRespondend/'+value.userKey
     );
     setrespondentId(id.data)

    setList(questions.data);
    setquestionNo(questions.data[0].id);
    setquestionToAnswer(0)
}, []);


function changeQuestion(currentquestionToAnswer,type){

  list.map(function(item, i){


    if(i===questionToAnswer )
    {
  if((value.answerFeedback ===null || value.answerFeedback ==='') && item.questionType==="5 Star" && type=='add' ){
   // console.log("empty")
    if (window.confirm('U heeft 0 sterren gegeven')) {
      // Save it!
      proceedToANotherQN(currentquestionToAnswer,type);

    } else {
      //console.log("skipped");
      console.log('Thing was not saved to the database.');

      return true;
    }
  }
  else if(value.answerFeedback ===null && item.questionType==="Yes/No"){
    return true;
  }
else{
  proceedToANotherQN(currentquestionToAnswer,type);
}
}
})

if(type=='sub'){
  proceedToANotherQN(currentquestionToAnswer,type);
}

 }

 function recordQnAnswer(data,questionType){

  var body = {
    response:value.answerFeedback,
    respondent_id:respondentId,
    questionID:data
  }

 if(value.answerFeedback !=null){
  var dataRes= axios({
    method: 'post',
    url: value.mainURL+'addQuestionFeedback',
    data: body
      }).then(function (response) {
          //console.log('response'+response);
          }).catch(function (error) {
             // console.log('error'+error);
          });
}
else{

}
setanswerFeedback(null);
//console.log(body);
 }

 function backSelected() {
  history.push('/khyts');
}

 function proceedToANotherQN(currentquestionToAnswer,type){
  if(currentquestionToAnswer===0 && type=='add'){
    updatequestionNo()
    setquestionToAnswer(currentquestionToAnswer+1)
  }
else if(currentquestionToAnswer<(value.questionNumber-1) && type=='add'){
  updatequestionNo()
  setquestionToAnswer(currentquestionToAnswer+1)
}

else if(currentquestionToAnswer>0 && type=='sub'){
  updatequestionNo()
  setquestionToAnswer(currentquestionToAnswer-1)
}
else if(currentquestionToAnswer==(value.questionNumber-1) && type=='add'){
  updatequestionNo()
  history.push('/bvh')
}
else{
  setquestionToAnswer(currentquestionToAnswer)
}
 }

    function updatequestionNo(){
list.map(function(item, i){
 if(i==questionToAnswer)
 {
  recordQnAnswer(item.id,item.questionType);

 }
})
    }
    return (

        <>
<div className="row">
          <a><div className="col-1 backBtnColor" onClick={() => backSelected()}> <i>Back</i></div></a>
        </div>
         {
         <>
                  <SingleQuestion
                  />
          </>
          }

      <div className="container-fluid centerText leaveSpace">

            <div className="col-12">
            <div className="btn btn-success btnColor2 col-md-2 mx-auto" onClick={() => changeQuestion(value.questionToAnswer,'sub')}>Terug</div>
            <lable className="btnSpace">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</lable>
            <div className="btn btn-primary btnColor col-md-2 mx-auto" onClick={() => changeQuestion(value.questionToAnswer,'add')}>Verder</div>
              </div>
            </div>

      </>
    );
  }

export default Questions;