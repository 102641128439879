import React from 'react';
import {Link} from 'react-router-dom';

function HelpWay() {


    return (
        <>
      <div className="centerText">
        <h4>Toelichting</h4>
        <hr/>
        </div>
        <p className="centerText">Ik heb een <em>compliment</em> over de <em>ondersteuning</em> van <em>Dienst of product 1</em> Geef hier uw toelichting:</p>
		
        
        <div className="container-fluid centerText">
        <div className="row"><div className="col-md-5 mx-auto"><textarea rows="10"></textarea></div></div>
            <div className="row"> <Link to="/vgtadr"><div className="btn btn-primary btnColor col-md-2 mx-auto">Versturen</div></Link></div>
        </div>
      </>
    );
  }

export default HelpWay;