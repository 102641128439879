import React,{useEffect,useState,useContext} from 'react';
import axios from "axios";
import {InfoContext} from '../store/InfoContext';
import { useHistory } from 'react-router';

function Department() {
    const [deparmentData, setDeparmentData] = useState([]);
    const value=useContext(InfoContext);
    const history=useHistory();

    function selectedDpt(dpt) {
      value.setdepartmentSelected(dpt);

      const result = axios(
        value.mainURL+'updateRespondent/'+value.userKey+'/'+dpt
      );
      history.push('/khyts');
    }

    useEffect( () => {
      async function fetchData() {
        var DisplayType='';
            if(value.userType==='user')
            {
            DisplayType='getDepartments';
            }
            else if(value.userType==='employee')
            {
              DisplayType='getTeams';
            }
        const result = await axios(
          value.mainURL+''+DisplayType+'/'+value.clientId,
        );
        setDeparmentData(result.data);
        }
        fetchData();

    }, [value.mainURL,value.clientId,value.userType]);

    return (
      <div className="centerText">
        <div className="col-12"><h4>Selecteer uw afdeling </h4></div>
        <hr/>
        <div className="row centerText">
        {deparmentData.map(item => (
          <div key={item.id}>
          <div className="btn btn-primary Departments centerText" onClick={() => selectedDpt(item.name)} >{item.name}</div>
       </div>
       ))}
        </div>
      </div>
    );
  }

export default Department;