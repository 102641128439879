import React,{useState, useEffect,useContext} from 'react';
import Department from './Department';
import axios from "axios";
import {Link} from 'react-router-dom';
import {InfoContext} from '../store/InfoContext';
import {Redirect} from 'react-router-dom';
import {InfoProvider} from '../store/InfoContext';
import ClientJS from "clientjs";
import { useHistory } from 'react-router';


const Home=({location})=> {
  const [isValidID, setisValidID] = useState('');
  const [isUserTypeValid, setIsUserTypeValid] = useState('No');
  const history=useHistory();
  const value=useContext(InfoContext);

    useEffect(async () => {
         //get all URL parameters
         const params=new URLSearchParams(location.search);


         const clientID_En=params.get('q/po');
         var clientID_Dec;
         try{
          var decodedStringAtoB = atob(clientID_En);
          clientID_Dec= decodedStringAtoB.substring(0, decodedStringAtoB.indexOf("/"));
         }
         catch(e){
          clientID_Dec='08h';
          history.push('/');
         }


         const clientValid = await axios(
         value.mainURL+'getClients/'+clientID_Dec,
        );

        (clientValid.data===1)?setisValidID('Yes'):setisValidID('No');
        if(isValidID==='No')
        {
          history.push('/');
        }

         value.setclientId(clientID_Dec);

         const uzerTyp=params.get('tyyemu');

         const getClientType = await axios(
         value.mainURL+'getClientType/'+uzerTyp,
        );
        if(getClientType.data==='inValid')
        {
          history.push('/');
        }
        (getClientType.data==='inValid')?setIsUserTypeValid('No'):setIsUserTypeValid('Yes');
        value.setUserType(getClientType.data);

        var initial=getClientType.data.toLowerCase();

        const result = await axios(
         value.mainURL+'introTexts/'+clientID_Dec+'/'+initial+'Experience',
        );

        const client = new ClientJS()
        const windowClient = new window.ClientJS();
        const fingerPrint = windowClient.getFingerprint();

        const alreadyFeedback = await axios(
          value.mainURL+'getAlreadyGaveFeedback/'+fingerPrint+'/'+getClientType.data+'/'+clientID_Dec
         );

        if(alreadyFeedback.data.data=='Already Responded')
        {
          history.push('/czdr')
        }

      const ipp=  await axios(value.mainURL+'getClientBrowserInfo');

recordRespondecy(fingerPrint,ipp.data.ip,ipp.data.browser,getClientType.data,clientID_Dec,getDevice());

        value.setuserKey(fingerPrint);
        value.setclientName(result.data[0].name);
        value.setIntroText(result.data[0].intro_text);
        value.setClosingText(result.data[0].closing_text)
    }, []);

function recordRespondecy(userKey1,ipAddress1,browser1,userCategory1,client_id1,device)
{
  var body = {
    userKey:userKey1,
    ipAddress:ipAddress1,
    browser:browser1,
    userCategory:userCategory1,
    client_id:client_id1,
    device_type:device
  }
  var dataRes= axios({
    method: 'post',
    url: value.mainURL+'addRespondency',
    data: body
      }).then(function (response) {
          //console.log('response'+response);
          }).catch(function (error) {
             // console.log('error'+error);
          });

}
function getDevice() {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop/laptop";
}


    return (
        <>

                <div className="text-center">User Experience meting {value.clientName}
                <hr/>
                </div>
                <p className="withParagraphs">{value.introText}</p>


            <div className="text-center">

            <Link to="/ddfty" className="btn btn-success btn-md">Start</Link>

            </div>
            </>

      );

}




export default Home;