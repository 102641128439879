import React,{useContext} from 'react';
import {InfoContext} from '../store/InfoContext';
import { useHistory } from 'react-router';

function ComplimentSuggestion() {
  const value=useContext(InfoContext);
  const {suggestionCompliment, setsuggestionCompliment} = useContext(InfoContext);
  const {anothersuggestionCompliment,setanothersuggestionCompliment}=useContext(InfoContext);
  const history=useHistory();

  function selecteCompOSugg(value){
    setsuggestionCompliment(value);
    history.push('/bvgrt')
  }
  function backSelected() {
    history.push('/khyts');
  }


    return (
        <>
      <div className="centerText">
      <div className="row">
          <a><div className="col-1 backBtnColor" onClick={() => backSelected()}> <i>Back</i></div></a>
        </div>
        {(anothersuggestionCompliment==='Yes')?
        <h4>Heeft u nog een compliment of een suggestie over {value.serviceName}?</h4>
        :
        <h4>Ik he been compliment of suggestive over {value.serviceName} </h4>}
        </div>
        <hr/>
        <div className="container-fluid centerText">
            <div className="row"><div onClick={() => selecteCompOSugg('Compliment')} className="btn btn-primary compliments col-md-3 mx-auto">Ik heb een compliment</div></div>
            <div className="row"><div onClick={() => selecteCompOSugg('Suggestion')} className="btn btn-primary compliments col-md-3 mx-auto">Ik heb een suggestie</div></div>

        </div>
      </>
    );
  }

export default ComplimentSuggestion;