import React ,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';



const NavBarHead= () =>{
    return(
        <>
        <nav className="navbar fixed-top navbar-dark maincolor ">
                <div className="d-none d-md-block float-md-left" >
                <img src="logo.png"  alt=""/>User Experience meeting
                </div>

                <div className="float-md-right d-inline ">
                <img src="dienstenlandkaart.png" width="60%"  alt=""/>
                </div>

            </nav>
            <nav className="navbar fixed-top navbar-dark secTop">
                </nav>
        </>
        );
}

export default NavBarHead;