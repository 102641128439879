import React,{useEffect,useState,useContext} from 'react';
import axios from "axios";
import {Link} from 'react-router-dom';
import {InfoContext} from '../store/InfoContext';
import { useHistory } from 'react-router';



function ServiceOrProduct() {
  const {productIteselfOrServiceReceived,setproductIteselfOrServiceReceive}=useContext(InfoContext);
  const value=useContext(InfoContext);
  const history=useHistory();
  const [description,setDescription]=useState('');
  const {anothersuggestionCompliment,setanothersuggestionCompliment}=useContext(InfoContext);
  const [respondentId,setrespondentId]=useState('');

  var body = {
    department:value.departmentSelected,
    feedback_type: value.suggestionCompliment,
    product:value.serviceName,
    product_or_service:value.productIteselfOrServiceReceived,
    description:description,
    respondent_id:respondentId
}



  useEffect(async () => {
    const id = await axios(
      value.mainURL+'getIdofRespondend/'+value.userKey
     );

     setrespondentId(id.data)
}, []);


function backSelected() {
  history.push('/bvgrt');
}

  function submitData(){
    if(description!=null){
    var dataRes= axios({
      method: 'post',
      url: value.mainURL+'addsuggestionsAndCompliments',
      data: body
        }).then(function (response) {
            //console.log('response'+response);
            }).catch(function (error) {
               // console.log('error'+error);
            });
         //   console.log(body);
          }
    setanothersuggestionCompliment('Yes');
    history.push('/khyts')
  }

                  function handleChange(e) {
                    setDescription(e.target.value);
                  }

    return (

        <>
      <div className="centerText">
      <div className="row">
          <a><div className="col-1 backBtnColor" onClick={() => backSelected()}> <i>Back</i></div></a>
        </div>
        <h4>Toelichting </h4>
        <hr/>
        </div>
        <p className="centerText">
        Ik heb een <em>{(value.suggestionCompliment==='Suggestion')?'suggestie':value.suggestionCompliment.toLowerCase()}</em> over de ondersteuning van <em>{value.serviceName}</em>. Geef hier uw toelichting:
        </p>


        <div className="container-fluid centerText">
        <div className="row"><div className="col-md-5 mx-auto textAreaDiv"><textarea className="textArea"  rows="10" value={description} onChange={handleChange} ></textarea></div></div>
            <div className="row"> <div className="btn btn-primary btnColor col-md-2 mx-auto" onClick={() => submitData()}>Versturen</div></div>
        </div>
      </>


  );
  }

export default ServiceOrProduct;