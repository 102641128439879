import React,{useContext} from 'react';
import {InfoContext} from '../store/InfoContext';



const Home=()=> {
    const value=useContext(InfoContext);

    return (
        <>
                <div className="text-center">Einde
                <hr/>
                </div>
                <p className="withParagraphs">{value.closingText}</p>
          

         
            </>

      );

}




export default Home;