import React,{useEffect,useState,useContext} from 'react';
import axios from "axios";
import {Link} from 'react-router-dom';
import {InfoContext} from '../store/InfoContext';
import { useHistory } from 'react-router';

function Services() {

  const {serviceName,setserviceName} = useContext(InfoContext);
    const [serviceData, setServiceData] = useState([]);
    const value=useContext(InfoContext);
    const history=useHistory();

    function selectedService(dpt){
      setserviceName(dpt);
      history.push('/vgtadr')
    }

    function backSelected() {
      history.push('/ddfty');
    }
    useEffect(async () => {
      if(value.userType==='user')
            {
        const result = await axios(
          value.mainURL+'getServices/'+value.clientId+'/'+value.departmentSelected,
        );
        setServiceData(result.data);
            }
            else if(value.userType==='employee')
            {
              const result = await axios(
                value.mainURL+'getEmployeeServices/'+value.clientId+'/'+value.departmentSelected,
              );
              setServiceData(result.data);
            }
            else{
              history.push('/');
            }


    }, []);

    function questions(){
      if(value.userType==='user')
              {
                history.push('/jkgtar');
              }
              else if(value.userType==='employee')
              {
                history.push('/jkgtar');
              }
              else{
                history.push('/');
              }
    }

    return (
      <div className="centerText">
        <div className="row">
          <a><div className="col-1 backBtnColor" onClick={() => backSelected()}> <i>Back</i></div></a>
        </div>
        <h4>Over welke dienst of applicatie wil je een compliment of suggestie geven? </h4>
        <hr/>

        <div className="row">
        <a onClick={() => questions()} className="btn btn-primary specialBtnColor compliments col-md-4 mx-auto">Ik heb geen compliment of suggestie meer</a>
        <div className="servicesTable">

          <table className="TableCenter">

        {

        serviceData.map(item => (
            <tr>
              <td>
                <div className="btn btn-primary Departments" onClick={() => selectedService(item.A)}>{item.A}</div>
                </td>
              <td>
                <label className="descriptionLable">{(item.B===null)?'':item.B}</label>
                </td>
            </tr>
      ))
      }
      </table>
        </div>
        </div>
      </div>
    );
  }

export default Services;