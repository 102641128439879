import React from 'react';

function AlreadyResponded() {
    return (
      <div className="unAuthorised">
        <h4>You already responded, You can not answer the questionnaire twice!!</h4>
      </div>
    );
  }

export default AlreadyResponded;