import React,{useEffect,useContext,useState} from 'react';
import {InfoContext} from '../store/InfoContext';

function SingleQuestion(props){
const value=useContext(InfoContext);
const {list,setList}=useContext(InfoContext);
const {answerFeedback,setanswerFeedback}=useContext(InfoContext);
const [volRange,setVolRange]=useState(5);
function handleChange(e) {
 setanswerFeedback(e.target.value);
}

function handlevolChange(e) {
  setanswerFeedback(e.target.value);
  setVolRange(e.target.value);
 }
function yesNo(val) {
  setanswerFeedback(val);
 }
    return(
        <>
{list.map((listItem,i) => (
  (value.questionToAnswer==i) &&
       <>
      <div className="centerText justify-content-center">
          <h4>Vraag {i+1} van {value.questionNumber}</h4>
          <hr/>
          </div>
          <p className="centerText">{listItem.question}<input type="hidden" name="eQuestionNumber" value={listItem.id}/></p>

          <div className="container centerText">
          {listItem.questionType ==='5 Star' &&
            <div className="centerText d-flex align-items-center justify-content-center">
            <div className="rate centerText justify-content-center">
      <input type="radio" id={i+'star5'} name={"rate"+listItem.id} onChange={handleChange} value="5" />
      <label for={i+'star5'} title="text">5 stars</label>
      <input type="radio" id={i+'star4'} name={"rate"+listItem.id} onChange={handleChange} value="4" />
      <label for={i+'star4'} title="text">4 stars</label>
      <input type="radio" id={i+'star3'} name={"rate"+listItem.id} onChange={handleChange} value="3" />
      <label for={i+'star3'} title="text">3 stars</label>
      <input type="radio" id={i+'star2'} name={"rate"+listItem.id} onChange={handleChange} value="2" />
      <label for={i+'star2'} title="text">2 stars</label>
      <input type="radio" id={i+'star1'} name={"rate"+listItem.id} onChange={handleChange} value="1" />
      <label for={i+'star1'} title="text">1 star</label>
    </div>
    <br/>
    <br/>
    </div>      }
          {listItem.questionType ==='10 Points' &&  <h2>  <input type="range" id="vol" name="vol" onChange={handlevolChange} value={volRange} min="0" max="10"/> <lable className="rangeLable">{volRange}/10</lable>  </h2>      }
          {listItem.questionType ==='Yes/No' && <h4> <input type="radio" name='{listItem.questionID}' onClick={() => yesNo('Yes')} value='Yes'/> Ja &nbsp;&nbsp;<br/>  <input type="radio" onClick={() => yesNo('No')} name='{listItem.questionID}' value='No'/>  Nee   </h4>      }
          {listItem.questionType ==='Description' && <div className="row"><div className="col-md-5 mx-auto"><textarea cols="50" onChange={handleChange} name='{listItem.questionID}' rows="10"></textarea></div></div>     }

          </div>
          </>
          ))}
              </>
    );
}

export default SingleQuestion;