import React,{Component} from "react";
import '../App.css';
import Home from './Home';
import Department from './Department';
import NavBarHead from './NavBarHead';
import UnAuthorised from './UnAuthorised';
import ComplimentSuggestion from './ComplimentSuggestion';
import Services from './Services'
import IsAbout from './IsAbout';
import ServiceOrProduct from './ServiceOrProduct';
import AlreadyResponded from './AlreadyResponded';
import HelpWay from './HelpWay';
import Questions from './Questions';
import End from './End';
import {InfoProvider} from '../store/InfoContext';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
  } from "react-router-dom";
class App extends Component{

    componentDidMount() {
        const { history } = this.props;
    
        window.addEventListener("popstate", () => {
          history.go(1);
        });
      }
    render(){
        return  (
            <Router>
                
                <NavBarHead/>
                <InfoProvider>
                <div className="myBody container">
                    
                <Switch>
                        <Route path="/" exact component={UnAuthorised}/>
                        <Route path="/acbgr"  component={Home}/>
                        <Route path="/ddfty" component={Department}/>
                        <Route path="/vgtadr" component={ComplimentSuggestion}/>
                        <Route path="/khyts" component={Services}/>
                        <Route path="/bvgrt" component={IsAbout}/>
                        <Route path="/draewt" component={ServiceOrProduct}/>
                        <Route path="/bvf" component={HelpWay}/>
                        <Route path="/jkgtar" component={Questions}/>
                        <Route path="/bvh" component={End} render={() => <Redirect to="/bvh" />}/>
                        <Route path="/czdr" component={AlreadyResponded}/>
                </Switch>
                </div>
                </InfoProvider>
            </Router>
        );
    }
}

export default App;