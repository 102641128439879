import React,{ useState, createContext} from 'react';

export const InfoContext=createContext();

export const InfoProvider = props =>{
//https://v31.dienstenlandkaart.nl
//http://127.0.0.1:8000
//https://portal.dienstenlandkaart.nl/
    const [mainURL,setmainURL]=useState('https://portal.dienstenlandkaart.nl/api/');
    const [userType,setUserType]=useState('')
    const [clientId,setclientId]=useState('');
    const [clientName,setclientName]=useState('');
    const [introText, setIntroText] = useState('');
    const [closingText, setClosingText] = useState('');
    const [departmentSelected,setdepartmentSelected]=useState('');
    const [suggestionCompliment,setsuggestionCompliment]=useState('');
    const [serviceName,setserviceName]=useState('');
    const [productIteselfOrServiceReceived,setproductIteselfOrServiceReceived]=useState('');
    const [toelichting,settoelichting]=useState('');
    const [questionNumber,setQuestionNumber]=useState('');
    const [questions,setQuestions]=useState([]);
    const [userKey,setuserKey]=useState('');
    const [ipAddress,setipAddress]=useState('');
    const [browser,setbrowser]=useState('');
    const [anothersuggestionCompliment,setanothersuggestionCompliment]=useState('');
    const [list,setList]=useState([]);
    const [i,seti]=useState('');
    const [questionToAnswer,setquestionToAnswer]=useState('');
    const [answerFeedback,setanswerFeedback]=useState('');
    const [questionNo,setquestionNo]=useState('');
    return(
<InfoContext.Provider
        value={{
            clientId,clientName,userType,questions,questionNumber,mainURL,introText,departmentSelected,suggestionCompliment,closingText,serviceName,productIteselfOrServiceReceived,anothersuggestionCompliment,list,i,questionToAnswer,answerFeedback,questionNo,userKey,
            setclientId,setclientName,setUserType,setQuestions,setQuestionNumber,setmainURL,setIntroText,setdepartmentSelected,setsuggestionCompliment,setClosingText,setserviceName,setproductIteselfOrServiceReceived,setanothersuggestionCompliment,setList,seti,setquestionToAnswer,setanswerFeedback,setquestionNo,setuserKey,
        }}>
    {props.children}
</InfoContext.Provider>
    );
}
