import React,{Component, useContext} from 'react';
import axios from "axios";

function UnAuthorised() {
    return (
      <div className="unAuthorised">
        <h4>You are not authorised to this site, you need a correct URL from your administrator</h4>
      </div>
    );
  }

export default UnAuthorised;