import React,{useEffect,useState,useContext} from 'react';
import axios from "axios";
import {Link} from 'react-router-dom';
import {InfoContext} from '../store/InfoContext';
import { useHistory } from 'react-router';

function IsAbout() {
  const {productIteselfOrServiceReceived,setproductIteselfOrServiceReceived}=useContext(InfoContext);
  const {suggestionCompliment, setsuggestionCompliment} = useContext(InfoContext);
  const {departmentSelected,setdepartmentSelected}=useContext(InfoContext);
  const value=useContext(InfoContext);
  const history=useHistory();

  function reasoN(value){
    setproductIteselfOrServiceReceived(value);
    history.push('/draewt')
  }

  function backSelected() {
    history.push('/vgtadr');
  }
    return (
        <>
      <div className="centerText">
      <div className="row">
          <a><div className="col-1 backBtnColor" onClick={() => backSelected()}> <i>Back</i></div></a>
        </div>
        <h4>Waar gaat het over? </h4>
        </div>
        <p className="centerText"><strong>
        Je werkt bij {value.departmentSelected} en je hebt een {(value.suggestionCompliment==='Suggestion')?'suggestie':value.suggestionCompliment.toLowerCase()}  over {value.serviceName}.</strong>  </p>
        <hr/>
        <div className="container-fluid centerText">
            <div className="row"><div className="btn btn-primary isAbout col-md-5 mx-auto" onClick={() => reasoN('Product')}>Het gaat over de dienst of het product zelf</div></div>
            <div className="row"><div className="btn btn-primary isAbout col-md-5 mx-auto" onClick={() => reasoN('Service')}>Het gaat over de manier waarop ik geholpen ben</div></div>
        </div>
      </>
    );
  }

export default IsAbout;